@import 'Selection.css';
@import 'Scrollbar.css';

.sidebar-collapsed > .icon {
  transform: rotate(180deg);
}

.icon {
  width: 30px;
  height: 30px;
  margin-left: 348px;
  cursor: pointer;
}

.sidebar-container {
  box-sizing: content-box;
}

.sidebar-collapsed > .sidebar-container {
  display: none;
}

.separator {
  background: #f8f8f8;
  border: none;
}

.autocomplete-option {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.11px;
  color: #1f2126;
}

.autocomplete-option > img {
  margin-right: 11px;
}

.option-partner-category-icon {
  width: 26px;
  height: 26px;
  margin-right: 5px;
  margin-left: -5px;
}

.option-partner-category-icon > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
