@import './assets/styles/Sidebar.css';
@import './assets/styles/Map.css';

@font-face {
  font-family: 'NutmegHeadline-Ultra';
  src: url('./assets/fonts/hansefit_nutmeg_font.woff2') format('woff2'),
    url('./assets/fonts/hansefit_nutmeg_font.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Black';
  src: url('./assets/fonts/Roboto-Black.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Black.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Arial-MT-Bold';
  src: url('./assets/fonts/Arial-MT-Bold.woff2') format('woff2'),
    url('./assets/fonts/Arial-MT-Bold.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.gm-bundled-control-on-bottom {
  right: 50px;
}

body,
html {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

#root {
  height: 100%;

}
