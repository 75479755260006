::-webkit-scrollbar {
    width: 9px;
    background-color: transparent !important;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #d8d8d8;
}

::-webkit-scrollbar-thumb:hover {
    background: #bbbbbb;
}

@media screen and (min-width: 640px) and (orientation: portrait) {
    .sidebar {
        height: 95% !important;
    }
}