.studio-search-result .studio-details-btn {
  opacity: 0;
  cursor: default;
}

.studio-search-result-hover .studio-details-btn {
  opacity: 1;
  cursor: pointer;
}

.studio-search-result:hover .studio-details-btn {
  opacity: 1;
  cursor: pointer;
}

@media screen and (max-height: 900px) {
  .selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto 10px 30px;
  }
}

@media only screen and (max-width: 425px) {
  .selection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px auto 10px 30px;
  }
}
